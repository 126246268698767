import React from 'react';
import UserEdu from '../components/UserEdu';
import Inspiration from '../components/Inspiration';
import '../styles/Home.css';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faLinkedin, faTwitter } from "@fortawesome/free-brands-svg-icons";
import Reachout from '../components/Reachout';

function Home() {
  return (
      <div className='home-container'>
        
        <div className='inspiration'>
          <Inspiration />
        </div>
        <UserEdu />
        <footer className="App-footer">
          <p>Trasparency is Key to African Economic Transformation and Poverty Alleviation</p>
          <div className='reachout'>
            <div className='socials'>
              <a href="https://www.linkedin.com/in/sicelumusa-gabuza-5707a265/">
                <FontAwesomeIcon icon={faLinkedin} size='2x' />
                <p>Linkedin</p>
              </a>
              <a href="https://x.com/ScelumusaG">
                <FontAwesomeIcon icon={faTwitter} size='2x' />
                <p>X</p>
              </a>
            </div>
            <div className='reachout-form'>
              <p>Having Issues With Your Account? Reachout to Us</p>
              <Reachout/>
            </div>
            
          </div>
        </footer>
      </div>
  );
}
export default Home;