import React from 'react';
import '../styles/Home.css';
import { Link } from 'react-router-dom';


const Inspiration = () => {
  return (
      <div className='inspiration-container'>

        <h1>Welcome to CREP!</h1>
        <h2>Empower Your Community with Your Voice</h2>

        <p><strong>Transform Your Community’s Future:</strong> By hiring a councilor through your vote, you’ve already made a powerful choice. Now, take your impact to the next level by evaluating and rating their work.</p>

        <p><strong>Be a Champion for Transparency:</strong> Your feedback drives accountability and ensures that your councilor delivers on their promises. With CREP, you hold the key to fostering effective governance and positive change in your ward.</p>

        <p><strong>Ready to Make a Difference?</strong> Join our mission to enhance local governance and build a stronger, more responsive community.</p>
        <Link to="/signup"><button>Register</button></Link>
      </div>
  );
}
export default Inspiration;