import React from 'react';
import CitizenStories from '../components/CitizenStories';
import Blogvents from '../components/Blogs_and_Events';
import '../styles/Perspectives.css';


function Stories() {
  return (
      <div className='stories-container'>
          <CitizenStories />
          <div className='blog-events'>
          <Blogvents />
        </div>
      </div>
  );
}
export default Stories;