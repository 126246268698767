import '../styles/Perspectives.css';
import success from '../assets/images/success.png'



const Blogvents = () => {
  return (
      <div className='blog-events'>
        <h1>Blogs and Community Events</h1>
        <h3>Section Under Construction</h3>
        <img src={success} alt="" className='image' />
      </div>
  );
}
export default Blogvents;