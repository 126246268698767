import React from "react";
import YouTube from 'react-youtube';
import '../styles/Home.css';

const UserEdu = () => {
  const videoId = 'Dmt9OdGrqEc';
  const navVideo = '4lJ1gsv-eHQ';
  const opts ={
    height: '200',
    width: '300',
    playerVars: {
      autoplay: 0,
    },
  };

  return (
    <div className="user-Edu">
      <h2>User Education</h2>
      <div className="councilor-role">
        <div className="statement">
          <h3>Make Informed Decisions When Rating</h3>
          <p>Understanding the full scope of your councilor's role is essential to providing fair and constructive feedback. By gaining insights into their tasks and challenges, you'll be better equipped to rate their performance accurately, providing valuable perspectives, and make informed choices when signing petitions. Empower yourself with knowledge and help drive positive change in your community.</p>
        </div>
        <div className="video">
          <div className="civic-academy">
            <YouTube videoId={videoId} opts={opts} />
            <p>Property of Civics Academy: <a href="https://civicsacademy.co.za/">Learn More</a></p>
          </div>
          <div className="app-navigation">
            <h3>Navigate the Platform</h3>
            <YouTube videoId={navVideo} opts={opts} />
          </div>
        </div>
      </div>
    </div>
  )
}

export default UserEdu;